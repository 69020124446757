.topBanner {
    min-height: 320px;
    background-image: url('/banner.png');
    background-size: cover;
    background-position: center;
}
  
.topBanner h1 {
    padding-top: 15%;
    font-size: 45px;
    font-weight: 700;
}