.nextArrow,
.prevArrow {
  background-color: #ffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  transform: rotate(-180deg);
  border-radius: 50%;
  top: 35%;
}
.nextArrow:hover,
.prevArrow:hover {
  background-color: #ffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  transform: rotate(-180deg);
  border-radius: 50%;
  top: 35%;
}
.nextArrow svg {
  position: absolute;
  right: 0;
}
.prevArrow svg {
  position: absolute;
  left: 0;
}